@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.nav {
// margin-top: 3rem;
margin-bottom: 2rem;
}

.nav__title {
  margin-top: 0;
  font-size: map-get($font-sizes, 'normal');
  font-weight: bold;
}

.nav__list {
  list-style-type: none;
  padding-left: unset;
}
.nav__item {
  margin-top: .75rem;
  // padding-top: 0.125rem;
  // padding-bottom: 0.125rem;
}

.nav__link {
  color: map-get($colours, 'text');
  line-height: 1;
}