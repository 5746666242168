@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

/*
***
* CLASSES
***
* sponsorship__title
* sponsorship__summary
* sponsorship__packages
* sponsorship__package
* sponsorship__package__icon
* sponsorship__package__title
* sponsorship__package__status
***
*/

.sponsorship {
  background-color: map-get($colours, 'background-accent');

  &__title {
    font-size: map-get($font-sizes, 'h2');
    margin-bottom: 1.5rem;
  }

  &__title,
  &__summary {
    text-align: center;
  }

  &__summary {
    max-width: map-get($breakpoints, '75ch');
    margin-inline: auto;
  }

  &__packages {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: map-get($breakpoints, 'lg');
    margin: 3rem auto;

    @include screen-up('sm') {
      flex-direction: row;
    }

  }
  &__package,
  &__package_sold {
    flex: 1 1 33.3%;
    padding: 1rem;
    min-width: 10rem;
    text-align: center;
    position: relative;
    margin-bottom: 2rem;

    @include screen-up(sm) {
      margin-bottom: unset;
    }

    &__icon {
      margin-bottom: 1rem;

      width:5rem;
      height: 3rem
    }
    &__title {
      font-size: map-get($font-sizes, 'h6');
      color: map-get($colours, 'primary');
    }
    &__status {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%) rotate(-25deg);
      background-color: rgba(map-get($colours, 'background'), .75);
      min-width: 10rem;
      height: 2rem;
      line-height: 2;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: .15em;
    }
  }
}
