@use "../../theme/base/variables" as *;
@use "../../theme/base/mixins" as *;

.page {
  min-height: 100vh;
}

.container {
  max-width: map-get($breakpoints, "xl");
  margin-inline: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container-md {
  max-width: map-get($breakpoints, "md");
  margin-inline: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container-lg {
  max-width: map-get($breakpoints, "lg");
  margin-inline: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container-75ch {
  max-width: map-get($breakpoints, "75ch");
  margin-inline: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
