@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.no-scroll,
.no-scrolling {
  height: 100%;
  overflow: hidden;
  transform: unset;
}

.modal__background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20rem;
  background-color: rgba(0, 0, 0, 0.637);
}

.modal__background.active {
  display: block;
}

.modal__wrapper {
  position: relative;
}

.modal__wrapper.active {
  display: block;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.modal__container.active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.close__button {
  cursor: pointer;
  position: absolute;
  top: 0rem;
  right: 1rem;
  display: block;
  font-size: 4rem;
  color: map-get($colours, "background");
  text-align: right;
  transition: all 100ms ease-out;
  transform-origin: right;

}

.close__button:hover {
  transform: scale(1.4);
}

.modal__title {
  margin-top: unset;
  font-weight: bold;
  font-size: 2rem;
  color: map-get($colours, "text");
}

.modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(map-get($colours, "background"), 0.85);
  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    img {
      width: 20rem;
      height: auto;
      // object-fit: cover;
      border-radius: 5px;
    }
  }

  &__paragraph {
    line-height: 1.5;
    // color: map-get($colours , 'primary' )
  }

  &__content {
    background-color: map-get($colours, "background-accent");
    box-shadow: 0 0.5rem 4rem rgba(map-get($neutrals, "grey-600"), 0.16);
    color: map-get($neutrals, "grey-200");
    padding: 2rem;
    border-radius: 5px;
    position: relative;
    width: 90%;
    max-width: 700px;
    & h2 {
      margin-top: 0;
      color: map-get($neutrals, "grey-100");
    }

    &__body {
      margin-top: 1.5rem;
      display: flex;
      flex-wrap: wrap;
    }

    &__actions {
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
    }

    & .close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 30px;
      font-weight: bold;
      color: #aaa;
      cursor: pointer;

      &:hover,
      &:focus {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  // }
}
