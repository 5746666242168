@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.message {
  padding: .75em 1em;
  color: map-get($neutrals, 'white');
  border: 1px solid currentColor;
  border-left-width: .25rem;
  border-radius: .25rem;
  font-size: map-get($font-sizes, 'sm');
}
.message.message_success {
  background-color: rgba(map-get($alerts, 'success'), .15);
  border-color: map-get($alerts, 'success');
  color: lighten(map-get($alerts, 'success'), 20%);
}
.message.message_error {
  background-color: rgba(map-get($alerts, 'error'), .15);
  border-color: map-get($alerts, 'error');
  color: lighten(map-get($alerts, 'error'), 20%);
}

.message.message_info {
  background-color: rgba(map-get($alerts, 'info'), .15);
  border-color: map-get($alerts, 'info');
  color: lighten(map-get($alerts, 'info'), 20%);
}

.message.message_warning {
  font-size: map-get($font-sizes, 'lg');
  background-color: rgba(map-get($alerts, 'warning'), .15);
  border-color: map-get($alerts, 'warning');
  color: lighten(map-get($alerts, 'warning'), 20%);
}