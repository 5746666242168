@use "./variables" as *;
@use "./mixins" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

@media screen and (min-width: map-get($breakpoints, "lg")) {
  html {
    scroll-padding-top: 7rem;
  }
}

body,
html,
#root {
  background-color: map-get($colours, "background");
  color: map-get($colours, "text");
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: map-get($fonts, "primary");
}

.no-scroll,
.no-scrolling {
  height: 100%;
  overflow: hidden;
  transform: unset;
}

@include screen-up(lg) {
  .no-scroll {
    height: unset;
    overflow: visible;
  }
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: map-get($colours, "primary");
  border-radius: 100px;
}

section,
.section {
  padding-top: clamp(3rem, 10vh, 6.25rem);
  padding-bottom: clamp(3rem, 10vh, 6.25rem);
}

svg {
  width: auto;
  height: auto;
  max-width: 100%;
}

.sr-only {
  visibility: hidden;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

h1 {
  font-size: map-get($font-sizes, "h1");
}
h2 {
  font-size: map-get($font-sizes, "h2");
}
h3 {
  font-size: map-get($font-sizes, "h3");
}
h4 {
  font-size: map-get($font-sizes, "h4");
}
h5 {
  font-size: map-get($font-sizes, "h5");
}

ul, ol{

  padding: 0;
}

p,
ul,
li,
details {
  line-height: map-get($line-heights, "normal");
}

img,
picture {
  height: auto;
  max-width: 100%;
}

a {
  color: map-get($colours, 'primary');
  text-decoration: none;

  &:hover {
    color: lighten(map-get($colours, 'primary'), 20%);
  
    // text-decoration: underline;
  }
}