@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.about {
  background-color: map-get($colours, 'background-shade');
  
}

.wrapper {
  padding-left: 1rem;
  padding-right: 1rem;

  @include screen-up(md) {
    display: flex;
  }

 
}

.title {
  max-width: map-get($breakpoints, 'sm');
  margin-bottom: 2rem;
  font-size: map-get($font-sizes, 'h2');
  font-weight: bold;
  color: map-get($colours, 'primary');

  @include screen-up(md) {   
    // max-width: map-get($breakpoints, 'xs');
    margin-bottom: unset;
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid map-get($colours, 'primary');
  }

}

.summary {
  @include screen-up(md) {
    padding-left: 1rem;
  }

  p {
    &:first-child {
      margin-top: unset;
    }
  }

}