@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.footer {
  background-color: map-get($colours, 'background-shade');
  padding-top: clamp(2rem, 5vh, 4.25rem);
}

.wrapper { 
  @include screen-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include screen-up(lg) {
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
    gap: 2rem;
  }
}

.footer__branding {
  margin-top: 0;
  display: block;
  width: 7rem;
}

.footer__logo {
  max-width: 4rem;
  opacity: 1;
}

.footer__summary {
  font-size: map-get($font-sizes, 'sm');
  padding-right: 2rem;
}

.footer__copyright {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: block;
  text-align: center;
  background-color: map-get($colours, 'background');
}