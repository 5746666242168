@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.quickstats {
  background-color: map-get($colours, "background");
}

.quickstats_inner {
  @include screen-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
    align-items: center;
  }
}
.wrapper {
  max-width: map-get($breakpoints, "60ch");
}

@include screen-up(sm) {
  .wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 4rem;
  }
}

.title {
}

.summary {
}
