@use "../../theme/base/variables.scss" as *;
@use "../../theme/base/mixins.scss" as *;

.btn,
.button {
  display: inline-block;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  text-decoration: none;
  text-wrap: none;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;
  transition: all ease-in-out .2s;
  font-weight: 500;
}

@include screen-up(sm) {
  .btn,
  .button {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.button,
.btn {
  & + .btn,
  & + .button {
    margin-left: 1rem;
  }
}

.button_sm,
.button_small,
.btn_small {
  padding: .75rem 1rem;
}

.active {
  color: map-get($colours, "secondary");
}

.button_link {
  padding: unset;
  background-color: unset !important;
  border-color: transparent !important;
  box-shadow: unset !important;

  &:hover {
    color: map-get($colours, 'primary');
  }
}


// Color-specific button styles
@each $name, $colour in $colours {
  .btn_#{$name} {
    color: $colour;
    border-color: $colour;

    @if lightness($colour) < 30% {
      color: lighten($colour, 70%);
      border-color: lighten($colour, 70%);
    } @else if lightness($colour) < 60% {
      color: darken($colour, 70%);
    } @else if lightness($colour) < 90% {
      color: darken($colour, 80%);
    } @else {
      color: darken($colour, 80%);
    }

    // Hover styles
    &:hover,
    &:focus {
      background-color: lighten($colour, 10%);
      color: darken($colour, 80%);
      box-shadow: 0 0 1.5rem rgba($colour, 0.15);
    }
  }

  .button_filled_#{$name} {
    background-color: $colour;
    // color: contrast($colour);
    border-color: $colour;

    // Hover styles for filled buttons
    &:hover,
    &:focus {
      background-color: lighten($colour, 10%);
      color: darken($colour, 80%);
    }
  }

  .button_outline_#{$name} {
    background-color: transparent;
    border-color: $colour;
    color: $colour;

    // Hover styles for outlined buttons
    &:hover,
    &:focus {
      background-color: lighten($colour, 10%);
      color: darken($colour, 80%);
    }
  }

  .btn_link_#{$name} {
    color: $colour;
    text-decoration: none;
    border: 0;
    background-color: transparent;

    // Hover styles
    &:hover,
    &:focus {
      color: lighten($colour, 10%);
      text-decoration: underline;
    }
  }
}
