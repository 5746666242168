@use '../../theme/base/variables' as *;
@use '../../theme/base/mixins' as *;

.statistics {
  display: grid;
  align-items: center;
  margin-inline: auto;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @include screen-up(sm) {
    grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
  }
}

.statistic {
  height: 100%;
  min-height: 220px;
  min-width: unset;
  border-radius: 1rem;
  padding: clamp(1.5rem, 2vw, 2.5rem) clamp(1.5rem, 2vw, 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: map-get($colours, 'background-accent');

  @include screen-up(sm) {
    margin-bottom: unset;
  }

  @include screen-up(md) {
    min-width: unset;
  }
}

.value {
  white-space: nowrap;
  font-size: map-get($font-sizes, 'xl');
  font-weight: bold;
  line-height: 1;
  color: map-get($colours, 'primary' );
}

.title {
  margin-top: 1rem;
  // white-space: nowrap;

  margin-bottom: unset;
  font-size: map-get($font-sizes, 'h5');
  font-weight: 600;
}
