@use "../../theme/base/_variables.scss"as *;
@use "../../theme/base/_mixins.scss"as *;

.video {
  position: relative;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    flex-shrink: 0;
  }
  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

}