@use "../../theme/base/_variables.scss"as *;
@use "../../theme/base/_mixins.scss"as *;

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: clamp(5rem, 10vw, 7rem);
  height: clamp(5rem, 10vw, 7rem);
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  background-color: rgba(map-get($colours, 'secondary'), .9);
  color: map-get($neutrals, 'white');
  pointer-events: none;

  &:hover {
    background-color: rgba(map-get($colours, 'secondary'), 1);
    animation: pulse;
    animation-duration: 2s;
    animation-play-state: running;
  }

  &:after {
    content: '';
    position: relative;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 50%;
    background-color: rgba(map-get($colours, 'secondary'), .9);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

}

.play__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: map-get($font-sizes, 'lg');
}

.isPlaying {
  opacity: 0;
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}