.agenda{
    color: #000000;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

.agenda__title{

    font-size: 3rem;
    margin-bottom: 0rem;
    margin-top: 1rem;
    color: #ffffff;

}

.agenda__subtitle{

    margin: 1rem 1rem;
    display: inline-block;
    max-width: 100%;
    color: #dddddd;
    font-size: 1rem;
    
}

/* Date Timings */

.date__container{

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    color: #ffffff;
    text-align: left;

}

.date__section{

    border: solid 0.05rem #ffffff;
    padding: 2rem;
    margin: 1rem;
    border-radius: 1rem;
    font-size: 1.2rem;

}

.date__text{

    color: #d4af37;
    font-weight: bold;
    margin-right: 1rem;
    display: block;
    margin-bottom: 0.5rem;
}

/*  */


.agenda__card{

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    margin: 1rem 1rem;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    background-color: var(--background-clr);
    border-bottom: solid 0.1rem #d4af37;
    color: #ffffff;

}

.agenda__time{

    display: block;
    margin-bottom: 0.5rem;
    
    font-weight: 800;
    font-size: 1.2rem;
    padding: 0.6rem 0.8rem;
    border-radius: var(--button-radius);
    background-color: var(--theme-clr);
    background-image: linear-gradient(135deg, var(--theme-clr), var(--secondary-clr));
    color: #ffffff;
    letter-spacing: 0.05rem;
    min-width: 30%;
}

.agenda__data{

    text-align: left;
}

.agenda__label{

    margin-left: 1rem;
    margin-bottom: 0.7rem;
    color: var(--theme-clr);
    font-weight: 700;
    font-size: 1.2rem;

}

.agenda__info{

    font-size: 0.9rem;
    font-weight: 400;
    margin-left: 1rem;
    text-align: left;
    color: var(--agenda-text);
    /* max-width: 50%; */
}

@media only screen and (min-width: 40rem){
    
    .date__container{
        grid-template-columns: repeat(2, 1fr);
        
    }

    .date{

        margin-bottom: 0.8rem;
    }

    .date__section{

        font-size: 1rem;
        text-align: center;
    
    }

    .date__text{

        color: #d4af37;
        font-weight: bold;
        margin-right: 1rem;
        display: inline;
    }

    .agenda{

        width: 580px;
        margin: 0 auto;
        
    }

    .agenda__title{

        font-size: 5rem;
        margin-bottom: 1rem;
    
    }


    .agenda__time{

        font-size: 1.5rem;
        margin-left: 1rem;
        margin-bottom: 0;
        display: inline-block;
    }
    
    .agenda__label{

        margin-left: 1rem;
    }

    .agenda__info{
        margin-left: 1rem;
        font-size: 1rem;
    }

    .agenda__card{
        margin: 1rem 0rem;

        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

}

@media only screen and (min-width: 50rem){
    
    .agenda{

        color: #000000;
        width: 780px;
        margin: 0 auto;
    }

    .agenda__subtitle{

        margin: 0 auto;
        margin-bottom: 2rem;
        width: 780px;
    }

    .agenda__card{

        width: 30rem;
        margin: 0 auto;
    }

}

@media only screen and (min-width: 64rem){
    
    .agenda{

        color: #000000;
        width: 800px;
        margin: 0 auto;
        
    }

}