@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

// .card_groups {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
// }

.panels {
  background-color: map-get($colours, "background");
}

.wrapper {
  @include screen-up(md) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
    grid-template-areas:
      "panel-one panel-one panel-three panel-three"
      "panel-two panel-two panel-three panel-three";
  }

  @include screen-up(xl) {
    grid-template-areas:
      "panel-one panel-one panel-three"
      "panel-two panel-two panel-three";
  }
}

.panel_one {
  grid-area: panel-one;
}
.panel_two {
  grid-area: panel-two;
}
.panel_three {
  grid-area: panel-three;
}

.panel_one,
.panel_two,
.panel_three {
  display: flex;
  flex: 1;

  & > * {
    margin-bottom: unset;
  }
}

/* 
  Custom panel styles 
*/

.cards {
  width: 100%;
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;

  @include screen-up(md) {
    margin-bottom: unset;
  }
}

.panel_title {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.panel_summary {
  @include screen-up(xs) {
    max-width: map-get($breakpoints, "2xs");
  }

  @include screen-up(md) {
    max-width: map-get($breakpoints, "xs");
  }
}

.judges {
  background-color: map-get($colours, "background-accent");
  background-image: url('../../../public/img/panels/judges-1.png');
  background-image: linear-gradient(to right, rgba(map-get($colours, 'background-accent'), 1), rgba(map-get($colours, 'background-accent'), 0)), url('../../../public/img/panels/judges-1.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}

.faq {
  background-color: map-get($colours, "background-accent");
  background-image: url('../../../public/img/panels/faq-1.png');
  background-image: linear-gradient(to right, rgba(map-get($colours, 'background-accent'), 1), rgba(map-get($colours, 'background-accent'), 0)), url('../../../public/img/panels/faq-1.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}

.entry_tips {
  background-color: map-get($colours, "background-accent");
  background-image: url('../../../public/img/panels/tips-1.png');
  background-image: linear-gradient(to bottom, rgba(map-get($colours, 'background-accent'), 1) 25%, rgba(map-get($colours, 'background-accent'), 0)), url('../../../public/img/panels/tips-1.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}
