@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;


.intro {
  font-size: map-get($font-sizes, 'h6');
  color: map-get($colours, 'accent');
}

/* Download Form */

.download-list {
  list-style: none;
  margin-bottom: 3rem;
}

.download-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid map-get($colours, 'background-accent' );

  @include screen-up(sm) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.download-subcategory {
  font-weight: normal;
  color: map-get($neutrals, 'grey-300');
}

.download-name {
  display: inline-block;
  margin-right: 2rem;
  font-weight: bold;
}

.download-link {

}


/* Next Steps */

.next-steps-wrapper {
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: .25rem;
  background-color: map-get($colours, 'background-accent');
}

.next-steps-heading {
  margin-top: unset;
  font-size: map-get($font-sizes, 'h4');
}

.next-steps-list {
margin-left: 1rem;
}

.next-steps-title {
  font-size: map-get($font-sizes, 'h6');
  margin-bottom: 0.75rem;
}

.next-steps-summary {
  color: map-get($colours, 'accent');
}

.next-steps-link {
  word-break: keep-all;
}

