@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

/* Two types of data */
.timetable {
  max-width: map-get($breakpoints, "xs");
  margin-inline: auto;
  margin-bottom: 2rem;
  color: map-get($colours, 'text');
}

.schedule {
  display: grid;
  grid-template-columns: repeat(1, minmax(map-get($breakpoints, '3xs'), 1fr));
  max-width: map-get($breakpoints, "md");
  margin-inline: auto;
  margin-bottom: 2rem;
  color: map-get($colours, 'text');
}

@media only screen and (min-width: map-get($breakpoints, 'md')){

  .schedule {
    display: grid;
    grid-template-columns: repeat(2, minmax(map-get($breakpoints, '3xs'), 1fr));
    
  }

}

/* Default data */

.row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(map-get($colours, "primary"), 0.25);
  border-radius: 1rem;
  padding-top: clamp(1.25rem, 2vw, 2rem);
  padding-bottom: clamp(1.25rem, 2vw, 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
}

.title {
  margin-top: unset;
  margin-bottom: unset;
  font-weight: bold;
  font-size: map-get($font-sizes, "lg");
}

.gold {
  color: map-get($colours, "primary");
}

.summary {
  margin-top: 0.5rem;
  margin-bottom: unset;
  color: map-get($neutrals, "grey-300");
}

.time {
  margin-left: auto;
  font-weight: bold;
  color: map-get($colours, "primary");
}

.night__title{

  text-align: center;
  font-weight: bold;
  font-size: 2rem;

  margin-bottom: 2rem;

}