@use "../../theme/base/variables" as *;
@use "../../theme/base/mixins" as *;

.hero {
  min-height: 95vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: end;

  background-color: var(--clr-light-grey);
  background-image: 
  linear-gradient(
      0deg,
      map-get($colours, "background-shade"),
      rgba(0, 0, 0, 0)
    ),
    url(../../../public/img/hero.png);
    background-attachment: fixed;
    
}

.hero {
  &__wrapper {
  }

  &__content {
  }

  &__title {
    font-size: map-get($font-sizes, "h3");
    word-break: keep-all;
    font-weight: bold;
    max-width: 100%;

    @include screen-up(sm) {
      font-size: map-get($font-sizes, "h2");
    }
    
    @include screen-up(md) {
      font-size: map-get($font-sizes, "h1");
    }
  }

  &__details {
    font-size: map-get($font-sizes, "h6");
    color: map-get($colours, "primary");
    margin-bottom: 3rem;

    @include screen-up(sm) {
    font-size: map-get($font-sizes, "h5");
    }
  }

  &__date {
  }

  &__venue {
  }

  &__message {
    margin-top: 3rem;
    margin-bottom: 2rem;
    max-width: fit-content;
  }
}

.sr-only {
  display: none;
}

@media only screen and (min-width: 64rem) {
  .hero {
    &__title {
      font-size: map-get($font-sizes, "h1");
      
    }
  }
}
