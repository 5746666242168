@use "../../theme/base/variables.scss" as *;
@use "../../theme/base/mixins.scss" as *;

.sub_title {
  margin-top: unset;
  color: map-get($colours, "primary");
}

.form {
  padding: 1rem;
}

.form input,
.form select,
.form textarea {
  width: 100%;
  padding: 0.75rem;
  // border-radius: 2px;
  background-color: map-get($colours, "background-accent");
  color: map-get($colours, 'text');
  border: 1px solid map-get($neutrals, "grey-700");

  &::placeholder {
  color: map-get($neutrals, "grey-400");
  }
}

.form textarea {
  font-family: map-get($fonts, "primary");
  font-size: map-get($font-sizes, "sm");
}


.category__choice {
  border-radius: 1rem;
  list-style: none;
  max-width: 100%;
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 0px 1.5rem rgba(map-get($neutrals, 'grey-700'), 0.25);
  

  &:hover {
    box-shadow: 0 0 2.5rem rgba(map-get($neutrals, 'grey-700'), 0.95);
  }
}

.form__item {
  margin-bottom: 1rem;
  flex: 1;
}

.form__checkbox {
  display: flex;
  align-items: flex-start;
}

.form__checkbox input[type="checkbox"] {
  position: relative;
  width: unset;
  margin-right: 0.5rem;
  top: 2px;
}

.form label {
  display: block;
  font-size: map-get($font-sizes, "sm");
  color: map-get($neutrals, "grey-200");
  margin-bottom: 0.5rem;
  cursor: pointer;
}

@include screen-up(md) {

  .form {
    max-width: map-get($breakpoints, '60ch');
    margin-inline: auto;
  }

  .form__two {
    padding-top: unset;
  }

}

@include screen-up(lg) {
  .form {
    max-width: map-get($breakpoints, 'xl');
  }

  .form__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .form__one,
  .form__two {
    padding-inline: 1rem;
  }
}

@include screen-up(xl) {

  .form__one {
    position: sticky;
    top: 20vh;
  }

  .form__group {
    display: flex;
  }

  .form__group .form__item {
    margin-right: 0.5rem;
  }

  .form__group .form__item + .form__item {
    margin-left: 0.5rem;
    margin-right: unset;
  }
}


/* Input states */

.input_error {
  border: 1px solid red;
  color: red;
}

.input_submitting {
  background-color: blue;
}

/* Messages */

.message_error {
  margin-top: 0.25rem;
  font-size: map-get($font-sizes, "sm");
  color: red;
}

.terms__description {
  color: map-get($neutrals, "grey-400");
}

.category__title {
  font-size: map-get($font-sizes, 'h4');
  margin-top: unset;
  text-align: left;
}

.category__choice {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 1rem !important;
}

.category__checkbox {
  display: inline;

  width: 1rem !important;
  height: 1rem;
  margin-right: 2rem;

  accent-color: map-get($colours, "primary");
  background-color: green;
  border: solid 0.5rem #ffffff;
  box-sizing: border-box;
}

.category__section {
  font-size: 1rem !important;
  margin-bottom: 0.5rem;
}

.category__label {
  // flex: 2;

  font-size: 1.6rem !important;
  color: map-get($colours, "primary") !important;
  font-weight: 800;
}

/* Form dropdown */

.iti__dropdown-content.iti--flexible-dropdown-width {
  background-color: map-get($colours, 'background') !important;
}
