@use '../../theme/base/variables' as *;

.title-wrapper {
  margin-bottom: 4rem;
  padding-top: 10rem;
  padding-bottom: 3rem;
  background-color: map-get($colours, 'background-shade');
}

.title {
  margin-top: unset;
  text-align: center;
}