@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.venue {
  background-color: map-get($colours, 'background');
}

.wrapper {
  @include screen-up(md) {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2,1fr);
  }
}

.media {
  @include screen-up(sm) {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2,1fr);
    margin-bottom: unset;
    position: relative;
  }

  @include screen-up(md) {
    transform: translateX(-5%);
  }

  @include screen-between(md, lg) {
    grid-template-columns: repeat(1,1fr);
  }

  @include screen-up(lg) {
    margin-left: -10vw;
  }

  &_first {
    position: relative;

    @include screen-down(sm) {
      display: none;
    }

    @include screen-between(md, lg) {
      display: none;
    }

    @include screen-up(lg) {
      display: block;
    }
  }

  &_first,
  &_secondary {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top right, rgba(map-get($colours, 'background-accent'), 1), rgba(map-get($colours, background), 0));
      border-radius: .25rem;
      z-index: 1;
    }

    img {
      border-radius: .25rem;
      overflow: hidden;
    }

  }
}

.content {}

.title {
  @include screen-up(md) {
    margin-top: unset;
  }
}