@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.tables__title__container {
  padding: 1rem;
  max-width: map-get($breakpoints, '75ch');
}

.tables__title {
  color: map-get($colours, "text");
  text-align: center;

  margin: 1rem auto !important;
}

.tables__subtitle {
  text-align: center;
}

.tables__span {
  color: map-get($colours, "primary");
  font-weight: 500;
}

.tables__card {
  border: solid 0.05rem map-get($colours, "primary");
  border-radius: 0.5rem;
  margin: 1rem auto 3rem;
  padding: 1rem;
  max-width: map-get($breakpoints, 'xs');
  background-color: map-get($colours, "background");
}

@include screen-up(md) {
  .tables__card {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.tables__type {
  text-align: center;
  color: map-get($colours, "primary");
  margin: 1rem 0;
}

.tables__price {
  text-align: center;
  font-size: map-get($font-sizes, 'h5');
  margin-bottom: 2rem;
  color: map-get($neutrals, 'grey-300');
}

.tables__list {
  list-style: none;
}

.tables__items {
  margin-bottom: 1rem;
}

.table__button {
  margin-top: 2rem;
  display: block;
}

@include screen-up(md) {
  .tables__title__container {
    margin: 0 auto;
  }

  .tables__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .tables__card {
    flex: 1;
    padding: 2rem 2rem;
  }
}
