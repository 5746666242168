@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

/* Tabs */

.category-tabs {
  display: flex;
  flex-direction: column;
  
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 1rem;
  }
}

@include screen-up(md) {
  .category-tabs {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    

    li {
      margin-bottom: 2rem;
      
    }
  }
}

.category-tab {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  border: solid 0.05rem map-get($neutrals, 'grey-500');
  padding: 1rem 4.5rem;
  border-radius: 0.5rem;
  color: map-get($neutrals, "grey-500");
  font-size: 1.5rem;
  transition: all 200ms ease;
  word-break: keep-all;
  background-color: map-get($colours, 'background');
  cursor: pointer;

  &:hover {
    transition: all 200ms ease;
    border: solid 0.05rem map-get($colours, "primary");
    color: map-get($colours, "primary");
  }
}

.active {
  border: solid 0.05rem map-get($colours, "primary");
  background-color: map-get($colours, 'background');
  color: map-get($colours, "primary");
}


/* Categories */

.category-list {
  margin-bottom: 4rem;
}
.category-item {
  border-radius: 1rem;
  box-shadow: 0 0px 1.5rem rgba(map-get($neutrals, 'grey-700'), 0.25);
  list-style: none;
  max-width: 100%;
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: default;

  &:hover {
    box-shadow: 0 0 2.5rem rgba(map-get($neutrals, 'grey-700'), 0.95);
  }
}

/* Category details */

.category-details {
  width: 100%;
  max-width: map-get($breakpoints, '60ch' );
}

.category-topic {
  font-size: 1rem;
  margin-bottom: .5rem;
  color: map-get($colours, "accent");
}
.category-name {
  margin-top: unset;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: map-get($line-heights, "tight");
}

@include screen-up(md) {
  .category-name {
    font-size: map-get($font-sizes, "h5");
  }
}

.category-link {
  display: inline-block;
  padding: 0.875rem 1.5rem;
  border: solid 0.05rem map-get($colours, "primary");
  border-radius: 0.5rem;
  color: map-get($colours, "primary");
  background-color: map-get($colours, "background");
  transition: all 200ms ease;
  cursor: pointer;

  &:hover {
    background-color: map-get($colours, "primary");
    color: map-get($colours, "background");
  }
}


@include screen-up(md) {
  .category-sponsor {
    margin-left: 2rem;
  }
}

.category-sponsor-label {
  color: map-get($colours, "text");
}

.category-sponsor-logo {
  width: 10rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.winner__title{

  font-weight: bold;
  color: map-get($colours , 'primary' );

}

.winner__item{

  font-size: 1.5rem;
  font-weight: bold;
  color: map-get($colours , 'primary' );
  margin-bottom: 1rem;

}

.highly__commended__title{

  font-weight: bold;
  color: map-get($colours , 'silver' );
  margin: 0 !important;

}

.commended__item{

  color: map-get($colours , 'silver' )

}

.finalists__subtitle{

  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: map-get($colours , 'primary');

}

.finalists__list{

  list-style: none;
  
}

.finalists__item{

  display: block;
  color: map-get($colours , 'primary');
  
}