@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.entry__wrapper {
  margin-bottom: 4rem;
}

.entry__intro {
  font-size: map-get($font-sizes, "h6");
  color: map-get($colours, "text");
}

.entry__title {
  color: map-get($colours, "primary");
  font-size: map-get($font-sizes, "h3");
  margin-bottom: 1.25rem;
}

.entry__subtitle {
  color: map-get($colours, "text");
  font-size: map-get($font-sizes, "h5");
}

.entry__lists {
  padding: 1rem;
  margin-left: 1rem;
}

.entry__bullets {
  margin-bottom: 0.5rem;
}