@use "../../theme/base/variables" as *;
@use "../../theme/base/mixins" as *;

.gallery { 
  margin-bottom: 2rem;

  @include screen-up (md) {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - .75rem));
    gap: 1rem;
  }

  @include screen-up (lg) {
    display: grid;
    grid-template-columns: repeat(3, calc(33.3% - 1rem));
    gap: 1rem;
  }

  img {
    display: block;
    border: 0;
    max-width: 100%;
    height: auto;
  }
}
