@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.navigation__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  z-index: 99;
  background-color: rgba(map-get($colours, "background"), 0);
  will-change: background-color;
  transition: all ease-in 0.3s;
  // padding: 2rem 2rem;

  &.is_open {
    background-color: rgba(map-get($colours, "background"), 1);
  }
  &.is_scrolled {
    background-color: rgba(map-get($colours, "background"), 1);
    box-shadow: 1rem 0 2rem rgba(map-get($colours, "primary"), 0.5);
    transition: all ease-out 0.2s;
  }
}

@keyframes load {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@include screen-up(lg) {
  .container {
    display: flex;
    justify-content: space-between;
  }

  .navigation {
    display: flex;
    align-items: center;
  }
}

.nav__logo {
  height: 3.2rem;
  margin-right: auto;
}

/*** Burger menu ***/

.burger__menu {
  outline: none;
  border: none;
  padding: 1.5rem 1rem 1rem 1rem;
  border-radius: 0.25rem;
  background-color: transparent;
  cursor: pointer;

  &__bar {
    display: block;
    width: 1.75rem;
    height: 0.05rem;
    border-radius: 100px;
    opacity: 1;
    background-color: map-get($neutrals, "white");
    transition: all 0.2s ease-in-out;

    &:nth-child(2) {
      margin-top: 0.7rem;
      margin-bottom: 0.5rem;
    }
    &:nth-child(3) {
      margin-top: 0.7rem;
      margin-bottom: 0.5rem;
    }

  }
}


@media screen and (min-width: 1024px) {
  .burger__menu {
    display: none;
  }
}

/*
  NAVIGATION STATE
*/

.nav_closed {
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
}

.nav_open {
  height: calc(100vh - 6rem);
  transition: all ease-in-out 0.3s;
  overflow: hidden;
}

@include screen-up(lg) {
  .nav_closed,
  .nav_open {
    height: unset;
    overflow: unset;
  }
}

/*
  MENU STYLES
*/

// Logo + Burger
.nav__header {
  display: flex;
  align-items: center;
}

/* Navigation Logo */
.nav__logo {
  display: inline-flex;
  margin-right: auto;
}

.nav__logo__image {
  min-width: 18rem;
  max-height: 4rem;
}

/*
***
* MENU OVERRIDES
***
* The classes below prepended with '.nav' override the
* styles defined in the <Menu> (higher specificity)
*
* Both .nav and .nav__list have been passed to <Menu>
* via className and listClassName props. Changes there
* need to be reflected in the class names below to maintain
* the styles
***
*/

// Shrink nav button
.navigation .nav__btn {
  padding: 0.5rem 1rem;
}

@include screen-down(lg) {
  .navigation .nav__list {
    padding-left: 0;
    list-style-type: none;
    flex-direction: column;
  }

  .navigation .nav__list li {
    margin-left: 0;
    border-bottom: 1px solid map-get($neutrals, "grey-900");
  }

  .navigation .nav__list a {
    display: block;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  .navigation .nav__list .nav__btn {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  /* Dropdown Styles */

  .navigation .nav_secondary li {
    border-bottom-color: transparent;
  }
}

.nav__button {
  border-radius: 0.2rem;
  color: map-get($colours, "background") !important;
  font-weight: 500;
  padding: 0.8rem 1.5rem !important;
}

.nav__button:hover {
  text-decoration: none;
}
