@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.judges__wrapper {}

.judges__item {
  max-width: 100%;
  padding: 1rem;
  list-style: none;
}

.judges__image {
  max-width: 100%;
}

.judges__name {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: map-get($font-sizes, 'h4');
}

.category-link {
  background-color: map-get($colours, "background");
  color: map-get($colours, "primary");
  padding: 0.8rem 1.6rem;
  border: 0.05rem solid map-get($colours, "primary");
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 1rem;
}

.category-link:hover {
  background-color: map-get($colours, "primary");
  color: map-get($colours, "background");
}

@include screen-up(md) {
  .judges__grid {
    max-width: map-get($breakpoints, 'lg');
    margin: 6rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(map-get($breakpoints, '3xs'), 1fr));
    grid-template-rows: auto;
  }
}


