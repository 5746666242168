@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.sponsors {
  padding: 3rem 1.5rem;
  background-color: map-get($colours, 'background');

  &__title {
    text-align: center;
    color: map-get($neutrals, "white");
  }
}

/*** Sponsors ***/
.sponsor {
  margin: 0 0.5rem 1rem;
  background-color: map-get($neutrals, "white");
  line-height: 1;
  border-radius: 0.375rem;

  &__group {
    margin-left: auto;
    margin-right: auto;

    &__logos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__title {
      color: map-get($neutrals, "white");
      font-size: 1.5rem;
      text-align: center;
    }
  }

  &__link {
    display: inline-flex;
  }

  &__image {
    border-radius: 0.25rem;
    transform: scale(0.95);
    transition: all ease-in-out 0.2s;

    &:hover {
      transform: scale(1);
    }
  }
}

.sponsor__group_wrapper {
  display: block;
}

@include screen-up(md) {
  .sponsor__group_wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .sponsor__group_wrapper .sponsor__group {
    margin-left: unset;
    margin-right: unset;
  }
}

.sponsor__group,
.sponsor__group--size-12 {
  max-width: map-get($breakpoints, "xl");
}

.sponsor__group--size-9 {
  max-width: map-get($breakpoints, "lg");
}
