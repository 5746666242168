@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  flex-direction: column;
  background: rgba(map-get($colours, 'background'), 1);
  z-index: 100;
}

// TransitionStyles.scss
.transition-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Assuming a semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-out;
}

.content-hidden {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.content-visible {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}



@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}