@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.card {
  border: solid 0.05rem map-get($neutrals, 'grey-100');
  padding: 2rem;
  margin: 1rem;
  border-radius: 1rem;
  font-size: map-get($font-sizes, 'normal');
}

.title {
  font-weight: bold;
  margin-block: unset;
  margin-right: 1rem;
  color: map-get($colours, 'primary');
}

.summary {
  margin-top: unset;
  margin-bottom: unset;
}

.item {
  display: flex;
  justify-content: center;
}