@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.accordion {
  border-radius: 5px;
  margin-bottom: 4rem;
  padding-inline: 1rem;
}

@include screen-up(sm) {
  .accordion {
    padding-inline: 2rem;
  }
}

@include screen-up(lg) {
  .accordion {
    padding-inline: unset;
  }
}

.accordion__title {
  word-break: keep-all;
  font-size: map-get($font-sizes, 'h3');
}

.accordion__details {
  margin-bottom: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0px 1.5rem rgba(map-get($neutrals, 'grey-700'), 0.25);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    box-shadow: 0 0 2.5rem rgba(map-get($neutrals, 'grey-700'), 0.95);
  }
}

.accordion__item {
  border-radius: 0rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.accordion__header {
  background-color: map-get($colours, "background");
  color: map-get($colours, "primary");
  font-weight: bold;
  border: none;
  text-align: left;
  cursor: pointer;
  border-radius: 0.8rem;
  font-size: map-get($font-sizes, 'h6');
  padding: 1.5rem;
}

.accordion__content {
  padding: 1rem 1rem 2rem;
  line-height: 1.35;
  color: #d5d5d5;
}

.accordion__content.open {
  display: block;
}

@include screen-up('lg') {
  .accordion {
    max-width: map-get($breakpoints, '75ch');
    margin-inline: auto;
  }
}
