$breakpoints: (
  '60ch': 60ch,
  '75ch': 75ch,
  '3xs':  18.75rem,   // 300px
  '2xs':  21.25rem,   // 340px
  'xs':   30rem,      // 480px
  'sm':   35rem,      // 560px
  'md':   48rem,      // 768px
  'lg':   64rem,      // 1024px
  'xl':   80rem,      // 1200px
  '2xl':  90rem,      // 1440px
  '3xl':  112.5rem    // 1800px
);

/*** 1.1 - Columns ***/

$column_count: 12;

/*** 2.0 - Colours ***/

$colours: (
  'primary':    #D4AF37,
  'secondary':  #ccc42e,
  'silver':  #c0c0c0,
  'accent':     #e0d0b3,
  'text':       #f6f7fe,
  'background': #0d0a11, //141012
  'background-accent': #18141e, // 1D1719
  'background-shade': #131018 //110d10
);

$neutrals: (
  'white':    #ffffff,
  'grey-100': #e5e8ed,
  'grey-200': #ddd4d9,
  'grey-300': #c2b2ba,
  'grey-400': #9c818f,
  'grey-500': #725a66,
  'grey-600': #4d3d45,
  'grey-700': #3c2f36,
  'grey-800': #31262b,
  'grey-900': #1A1416
);

$alerts: (
  'success': #6cff67,
  'error': #ffb1b1,
  'warning': #ffd87e,
  'info': #4cd8ff
);


/*** 3.0 - Fonts ***/

$font-sizes: (
  'h1': clamp(3rem, 10vw, 3.5rem),
  'h2': clamp(2.5rem, 10vw, 3rem),
  'h3': clamp(1.65rem, 10vw, 2.5rem),
  'h4': clamp(1.5rem, 10vw, 2.0625rem),
  'h5': clamp(1.15rem, 10vw, 1.625rem),
  'h6': clamp(.875rem, 10vw, 1.25rem),
  'sm': clamp(.75rem, 10vw, .875rem),
  'normal': 1rem,
  'lg': clamp(1.125rem, 10vw, 1.25rem),
  'xl': clamp(4rem, 15vw, 5rem),
);

$fonts: (
  'primary': 'Montserrat'
);

$line-heights: (
  'none': 1,
  'tight': 1.2,
  'normal': 1.65,
  'loose': 2
);

$spacing: (
  'xs': .375,
  'sm': .75,
  'normal': 1,
  'lg': 1.25,
  'xl': 1.5,
);