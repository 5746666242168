@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;


.category__cards {
  background-color: map-get($colours, 'background');
}

.title {
  margin-top: unset;
  text-align: center;
}

.wrapper { 
  @include screen-up(lg) {
    display: flex;
    gap: 2rem;
  }
}

.category__card {
  flex: 1;
  padding: 4rem 2.5rem 2rem;
  border-bottom: .5em solid map-get($colours, 'primary');
  border-radius: 1rem;
  margin-bottom: 2rem;
  background-color: map-get($colours, 'background-accent');

  @include screen-up(lg) {
    margin-bottom: unset;
  }

  &__title {
    margin-top: unset;
    font-size: map-get($font-sizes, 'h5');
    // color: map-get($colours, 'primary');
  }
  &__summary {
    margin-bottom: 2rem;
  }
}


.category__card__title {
}