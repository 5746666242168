@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.wrapper {
  margin-left: -1rem;
  margin-right: -1rem;
}

.title {
  margin-top: unset;
  text-align: center;
}

.contact {
  &_list {
    list-style-type: none;
    padding-left: unset;
    
    @include screen-up(sm) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &_item {
    margin-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include screen-up(sm) {
      width: calc(50%);
      margin-bottom: 1rem;

    }

    @include screen-up(lg) {
      width: calc(25%);
      margin-bottom: unset;
    }
  }
  &_role {
    text-transform: uppercase;
    letter-spacing: .07em;
    font-weight: normal;
    font-size: map-get($font-sizes, 'sm');
    min-height: 2rem;
  }
  &_photo {
    width: 100%;
    margin-bottom: 1rem;
    @include screen-up('sm') {
      // max-height: 10rem;
      height: 30vh;
      object-fit: cover;
      border-radius: 0.2rem;
    }
  }
  &_name {
    font-size: map-get($font-sizes, 'h6');
    margin-top: unset;
    margin-bottom: .75rem;
  }
  &_details {
    margin-top: unset;
  }
  &_email,
  &_number {
    display: block;
  }
}
