@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.holding__wrapper{
    width: 100%;
    margin-inline: auto;
    min-height: 100vh;
    max-width: map-get($breakpoints, '60ch' );
    display: grid;
    place-items: center;
    background-color: map-get($colours , 'background' );
}

.holding__content__wrapper{


    border: solid 0.1rem map-get($colours , 'primary' );
    padding: 3rem;
    border-radius: 1rem;

}


.holding__logo{

    width: 20rem;
}

.holding__title{

    font-size: 2rem;

}